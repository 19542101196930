import './App.css';
import Grid from '@mui/material/Unstable_Grid2';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { Duration } from 'luxon';
import shortBuzzer from './sounds/short-buzzer.wav';
import longBuzzer from './sounds/long-buzzer.mp3';
import wpBallImg from './images/waterpoloball.jpg';

function App() {
  const [isClockRunning, setIsClockRunning] = useState(false);
  const [shotClockSeconds, setShotClockSeconds] = useState(Duration.fromObject({ seconds: 30 }));
  const [gameClockSeconds, setGameClockSeconds] = useState(Duration.fromObject({ seconds: 420 }));
  const [homeTimeoutsLeft, setHomeTimeoutsLeft] = useState(3);
  const [awayTimeoutsLeft, setAwayTimeoutsLeft] = useState(3);
  const [quarter, setQuarter] = useState(1);
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [isShotClockExpired, setIsShotClockExpired] = useState(false);
  const [isGameClockExpired, setIsGameClockExpired] = useState(false);
  const shortBuzzerAudio = new Audio(shortBuzzer);
  const longBuzzerAudio = new Audio(longBuzzer);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (!isClockRunning || gameClockSeconds.seconds <= 0) {
      clearInterval(interval);
    } else if (isClockRunning) {
      interval = setInterval(() => {
        setGameClockSeconds(seconds => seconds.minus({ seconds: 1}));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isClockRunning, gameClockSeconds]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (!isClockRunning || shotClockSeconds.seconds <= 0) {
      clearInterval(interval);
    } else if (isClockRunning) {
      interval = setInterval(() => {
        setShotClockSeconds(seconds => seconds.minus({ seconds: 1}));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isClockRunning, shotClockSeconds]);

  useEffect(() => {
    if (shotClockSeconds.seconds <= 0 && !isShotClockExpired) {
      shortBuzzerAudio.play().catch(err => console.log(err));
      setIsShotClockExpired(true);
    } else if(shotClockSeconds.seconds > 0 && isShotClockExpired) {
      setIsShotClockExpired(false);
    }
  }, [isShotClockExpired, shotClockSeconds]);

  useEffect(() => {
    if (gameClockSeconds.seconds <= 0 && !isGameClockExpired) {
      longBuzzerAudio.play().catch(err => console.log(err));
      setIsGameClockExpired(true);
      setIsClockRunning(false);
    } else if(gameClockSeconds.seconds > 0 && isGameClockExpired) {
      setIsGameClockExpired(false);
    }
  }, [isGameClockExpired, gameClockSeconds]);
  
  const resetShotClock = (): void => {
    if(gameClockSeconds.seconds >= 30) {
      setShotClockSeconds(Duration.fromObject({ seconds: 30 }));
    } else {
      setShotClockSeconds(Duration.fromObject({ seconds: gameClockSeconds.seconds }));
    }
  }

  const resetGameClock = (): void => {
    setIsClockRunning(false);
    setGameClockSeconds(Duration.fromObject({ seconds: 420 }));
    setShotClockSeconds(Duration.fromObject({ seconds: 30 }));
  }

  const resetAll = (): void => {
    setIsClockRunning(false);
    setGameClockSeconds(Duration.fromObject({ seconds: 420 }));
    setShotClockSeconds(Duration.fromObject({ seconds: 30 }));
    setHomeTimeoutsLeft(3);
    setAwayTimeoutsLeft(3);
    setHomeScore(0);
    setAwayScore(0);
    setQuarter(1);
  }

  const useHomeTimeout = (): void => {
    if (homeTimeoutsLeft === 0) {
      setHomeTimeoutsLeft(3);
    } else {
      setHomeTimeoutsLeft(homeTimeoutsLeft - 1);
    }
  }

  const useAwayTimeout = (): void => {
    if (awayTimeoutsLeft === 0) {
      setAwayTimeoutsLeft(3);
    } else {
      setAwayTimeoutsLeft(awayTimeoutsLeft - 1);
    }
  }

  const updateQuarter = (): void => {
    if (quarter === 4) {
      setQuarter(1);
    } else {
      setQuarter(quarter + 1);
    }
  }

  const decrementHomeScore = (): void => {
    if (homeScore !== 0) {
      setHomeScore(homeScore - 1);
    }
  }

  const decrementAwayScore = (): void => {
    if (awayScore !== 0) {
      setAwayScore(awayScore - 1);
    }
  }

  const incrementGameClock = (): void => {
    if (!isClockRunning) {
      setGameClockSeconds(gameClockSeconds.plus({ seconds: 1 }));
    }
  }

  const decrementGameClock = (): void => {
    if (!isClockRunning && gameClockSeconds.seconds > 0 ) {
      setGameClockSeconds(gameClockSeconds.minus({ seconds: 1 }));
    }
  }

  const incrementGameClock30 = (): void => {
    if (!isClockRunning) {
      setGameClockSeconds(gameClockSeconds.plus({ seconds: 30 }));
    }
  }

  const decrementGameClock30 = (): void => {
    if (!isClockRunning && gameClockSeconds.seconds >= 30 ) {
      setGameClockSeconds(gameClockSeconds.minus({ seconds: 30 }));
    }
  }

  const incrementShotClock = (): void => {
    if (!isClockRunning && shotClockSeconds.seconds < 30) {
      setShotClockSeconds(shotClockSeconds.plus({ seconds: 1 }));
    }
  }

  const decrementShotClock = (): void => {
    if (!isClockRunning && shotClockSeconds.seconds > 0 ) {
      setShotClockSeconds(shotClockSeconds.minus({ seconds: 1 }));
    }
  }

  const handleKeyboardEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch(event.key) {
      case ' ':
        setIsClockRunning(!isClockRunning);
        return;
      case 's':
        resetShotClock();
        return;
      case 'g':
        resetGameClock();
        return;
    }
  }

  return (
    <div
      tabIndex={0}
      className="App"
      onKeyUp={event => handleKeyboardEvent(event)}
    >
      <Grid container spacing={1} disableEqualOverflow>
        <Grid xs={5}>
          <Card sx={ isShotClockExpired ? { backgroundColor: "red" } : {}}>
            <Grid container>
              <Grid xs={12}>
                Shot
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                {/* <Card> */}
                  <Grid container alignItems="center">
                    <Grid xs={8}><h1>{shotClockSeconds.toFormat(':ss')}</h1></Grid>
                    <Grid xs={4}>
                      <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group"
                        disabled={isClockRunning}
                      >
                        <IconButton aria-label="increase" size="small" onClick={incrementShotClock} 
                          disabled={isClockRunning || shotClockSeconds.seconds >= 30}
                        >
                          <ArrowDropUpIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={decrementShotClock} 
                          disabled={isClockRunning || shotClockSeconds.seconds <= 0}
                        >
                          <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                {/* </Card> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={2}>
          <Card>
            <Grid container>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                <AccessAlarmIcon />
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                <Button variant="text" size="large" onClick={updateQuarter}><h1>Q{quarter}</h1></Button>
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                <Avatar alt="water polo ball" src={wpBallImg} style={{ marginBottom: '1%' }}/>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={5}>
          <Card sx={ isGameClockExpired ? { backgroundColor: "red" } : {}}>
            <Grid container>
              <Grid xs={12}>
                Game
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                {/* <Card> */}
                  <Grid container alignItems="center" style={{ marginRight: '10%'}}>
                    <Grid xs={8}><h1>{gameClockSeconds.toFormat('mm:ss')}</h1></Grid>
                    <Grid xs={2}>
                      <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group"
                        disabled={isClockRunning}
                      >
                        <IconButton aria-label="increase" size="small" onClick={incrementGameClock} disabled={isClockRunning}>
                          <ArrowDropUpIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small" onClick={decrementGameClock} 
                          disabled={isClockRunning || gameClockSeconds.seconds <= 0}
                        >
                          <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                      </ButtonGroup>
                    </Grid>
                    <Grid xs={2}>
                      <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical outlined button group"
                        disabled={isClockRunning}
                      >
                        <Button aria-label="increase" size="small" onClick={incrementGameClock30} disabled={isClockRunning}>
                          +30
                        </Button>
                        <Button aria-label="delete" size="small" onClick={decrementGameClock30} 
                          disabled={isClockRunning || gameClockSeconds.seconds < 30}
                        >
                          -30
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                {/* </Card> */}
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card>
            <Grid container>
              <Grid xs={12}>
                Home
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                {/* <Card> */}
                  <Grid container alignItems="center">
                    <Grid xs={8}><h1>{homeScore}</h1></Grid>
                    <Grid xs={4}>
                      <ButtonGroup
                        orientation="vertical"
                        aria-label="home score incrementer"
                      >
                        <IconButton aria-label="increase" size="small" onClick={() => setHomeScore(homeScore + 1)}>
                          <ArrowDropUpIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="decrease" size="small" onClick={decrementHomeScore} disabled={homeScore <= 0}>
                          <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                {/* </Card> */}
              </Grid>
              <Grid xs={12}>
                <ButtonGroup
                  orientation="horizontal"
                  aria-label="home-timeouts"
                >
                  <IconButton aria-label="home-timeouts" color="warning" onClick={useHomeTimeout}>
                    {[...Array(homeTimeoutsLeft)].map((_, i) =>
                      <CircleIcon key={i} />
                    )}
                    {[...Array(3 - homeTimeoutsLeft)].map((_, i) =>
                      <CircleOutlinedIcon key={i} />
                    )}
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={6}>
          <Card>
            <Grid container>
              <Grid xs={12}>
                Away
              </Grid>
              <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
                {/* <Card> */}
                  <Grid container alignItems="center">
                    <Grid xs={8}><h1>{awayScore}</h1></Grid>
                    <Grid xs={4}>
                      <ButtonGroup
                        orientation="vertical"
                        aria-label="away score incrementer"
                      >
                        <IconButton aria-label="increase" size="small" onClick={() => setAwayScore(awayScore + 1)}>
                          <ArrowDropUpIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="decrease" size="small" onClick={decrementAwayScore} disabled={awayScore <= 0}>
                          <ArrowDropDownIcon fontSize="small" />
                        </IconButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                {/* </Card> */}
              </Grid>
              <Grid xs={12}>
                <ButtonGroup
                  orientation="horizontal"
                  aria-label="away-timeouts"
                >
                  <IconButton aria-label="away-timeouts" color="warning" onClick={useAwayTimeout}>
                    {[...Array(awayTimeoutsLeft)].map((_, i) =>
                      <CircleIcon key={i} />
                    )}
                    {[...Array(3 - awayTimeoutsLeft)].map((_, i) =>
                      <CircleOutlinedIcon key={i} />
                    )}
                  </IconButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs={12} display="flex" justifyContent="center" alignItems="center">
          <Card>
            <Grid container xs={12} alignItems="center" spacing={4}>
              <Grid xs={4}>
                <Tooltip title="shortcut: SPACE BAR" placement="top">
                  {
                    isClockRunning
                      ? <Button variant="contained" size="large" color="error" onClick={() => setIsClockRunning(false)}>Stop</Button>
                      : <Button variant="contained" size="large" color="success" onClick={() => setIsClockRunning(true)}>Start</Button>
                  }
                </Tooltip>
              </Grid>
              <Grid xs={4}>
                <Tooltip title="shortcut: S" placement="top">
                  <Button variant="contained" size="large" onClick={resetShotClock} style={{ marginBottom: '5%'}}>Reset Shot Clock</Button>
                </Tooltip>
              </Grid>
              <Grid xs={4}>
                <Tooltip title="shortcut: G" placement="top">
                  <Button variant="contained" size="large" onClick={resetGameClock} style={{ marginRight: '5%', marginBottom: '5%'}}>Reset Game Clock</Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid xs display="flex" justifyContent="center" alignItems="center">
          <Card>
            <Button variant="contained" size="large" color="error" onClick={resetAll} style={{ margin: '5%'}}>Reset All</Button>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
